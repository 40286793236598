import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { InlineSVGModule } from 'ng-inline-svg';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { KtDialogService, LayoutConfigService, LayoutRefService,
  MenuAsideService, MenuConfigService, MenuHorizontalService, PageConfigService, SplashScreenService, SubheaderService } from './core/_base/layout';
import { ThemeModule } from './views/theme/theme.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LayoutConfig } from './core/_config/layout.config';
import { AngularDataContext, ClientDataContextConfig, DATA_CONTEXT_CONFIG, MostModule } from '@themost/angular';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environment';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormioModule } from '@formio/angular';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';

export function DataContextConfigFactory(http: HttpClient, appConfig: LayoutConfigService): Promise<ClientDataContextConfig> {
  let configurationUrl = 'assets/config/app.production.json';
  if (environment.production === false) {
    configurationUrl = 'assets/config/app.development.json';
  }
  return http.get<any>(configurationUrl).toPromise().then((result) => {
    // load app config settings
    if (appConfig) {
      appConfig.setConfig(result, true);
    }
    return Promise.resolve({
      base: result.settings.remote.server,
      options: {
        useMediaTypeExtensions: false,
        useResponseConversion: true
      }
    });
  });
}

// tslint:disable-next-line: typedef
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


export function initializeLayoutConfig(appConfig: LayoutConfigService): () => void {
	// initialize app by loading default demo layout config
	return () => {
		if (appConfig.getConfig() === null) {
			appConfig.loadConfigs(new LayoutConfig().configs);
		}
	};
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
		BrowserModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    CoreModule,
    ThemeModule,
    ModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    InlineSVGModule.forRoot(),
    MostModule.forRoot({
      base: '/',
      options: {
        useMediaTypeExtensions: false,
        useResponseConversion: true
      }
    }),
    FormioModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    LayoutConfigService,
	  LayoutRefService,
    MenuConfigService,
		PageConfigService,
		KtDialogService,
		SplashScreenService,
    {
			// layout config initializer
			provide: APP_INITIALIZER,
			useFactory: initializeLayoutConfig,
			deps: [LayoutConfigService], multi: true
		},
    {
			// context initializer
			provide: APP_INITIALIZER,
			useFactory: (http: HttpClient, context: AngularDataContext, appConfig: LayoutConfigService) =>
          () => {
              // load application configuration
              return DataContextConfigFactory(http, appConfig).then((config: ClientDataContextConfig) => {
                const options = context.getService().getOptions();
                Object.assign(options, config.options);
                context.setBase(config.base);
              });
          },
      deps: [ HttpClient, AngularDataContext, LayoutConfigService ],
      multi: true
		},
    SubheaderService,
		MenuHorizontalService,
		MenuAsideService,
    {
      provide: LOCALE_ID,
      useFactory: (translateService: TranslateService) => {
        translateService.setDefaultLang(environment.defaultLocale);
        translateService.use(environment.defaultLocale);
        return environment.defaultLocale;
      },
      deps: [
        TranslateService
      ]
    },
    {
      provide: DATA_CONTEXT_CONFIG,
      useFactory: DataContextConfigFactory,
      deps: [
        HttpClient
      ]
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
