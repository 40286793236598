<!-- begin:: Header Mobile -->
<div id="kt_header_mobile" class="kt-header-mobile">
	<div class="kt-header-mobile__brand">
		<a class="kt-header-mobile__logo" routerLink="/">
			<img alt="logo" [attr.src]="headerLogo"/>
		</a>
	</div>
	<div class="kt-header-mobile__toolbar">
		<button [hidden]="!asideDisplay" class="kt-header-mobile__toggler kt-header-mobile__toggler--left" id="kt_aside_mobile_toggler">
			<span></span>
		</button>
		<button class="kt-header-mobile__toolbar-toggler" id="kt_header_mobile_toggler"><span></span></button>
		<button ktToggle [options]="toggleOptions" class="kt-header-mobile__toolbar-topbar-toggler" id="kt_header_mobile_topbar_toggler">
			<i class="flaticon-more-1"></i>
		</button>
	</div>
</div>
<!-- end:: Header Mobile -->
