import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponent } from './base/base.component';
import { RouterModule } from '@angular/router';
import { HtmlClassService } from './html-class.service';
import { HeaderComponent } from './header/header.component';
import { HeaderMobileComponent } from './header/header-mobile/header-mobile.component';
import { BrandComponent } from './brand/brand.component';
import { TopbarComponent } from './header/topbar/topbar.component';
import { MenuHorizontalComponent } from './header/menu-horizontal/menu-horizontal.component';
import { SubheaderComponent } from './subheader/subheader.component';
import { AsideLeftComponent } from './aside/aside-left.component';
import { CoreModule } from 'src/app/core/core.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { InlineSVGModule } from 'ng-inline-svg';
@NgModule({
  declarations: [
    BaseComponent,
    // headers
		HeaderComponent,
    BrandComponent,
		HeaderMobileComponent,
    // subheader
		SubheaderComponent,
		// topbar components
		TopbarComponent,
		// aside left menu components
		AsideLeftComponent,
		// horizontal menu components
		MenuHorizontalComponent,
  ],
  exports: [
    BaseComponent,
    // headers
		HeaderComponent,
    BrandComponent,
		HeaderMobileComponent,
    // subheader
		SubheaderComponent,
		// topbar components
		TopbarComponent,
		// aside left menu components
		AsideLeftComponent,
		// horizontal menu components
		MenuHorizontalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    PerfectScrollbarModule,
    InlineSVGModule
  ],
  providers: [
		HtmlClassService,
	],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class ThemeModule { }

